import { useApi } from "../../../hooks/useApi";
import { Contact } from "../types/userTypes";

export const useContacts = (): {
  createContact: (contact: Contact) => Promise<Contact>;
  updateContact: (contact: Contact) => Promise<void>;
  deleteContact: (id: string) => Promise<void>;
  addContactRole: (id: string, role: string) => Promise<Contact>;
  deleteContactRole: (id: string, role: string) => Promise<void>;
  createContactAccount: (id: string, language: string) => Promise<void>;
  deleteContactAccount: (id: string) => Promise<void>;
} => {
  const { call } = useApi();

  const createContact = (contact: Contact) =>
    call(`/supercotrolia/me/contact`, {
      data: contact,
    });

  const updateContact = (contact: Contact) =>
    call(`/supercotrolia/me/contact/${contact.id}`, {
      data: contact,
      method: "PUT",
    });

  const deleteContact = (id: string) =>
    call(`/supercotrolia/me/contact/${id}`, {
      method: "DELETE",
    });

  const addContactRole = (id: string, role: string) =>
    call(`/supercotrolia/me/contact/${id}/role/${role}`, { method: "POST" });

  const deleteContactRole = (id: string, role: string) =>
    call(`/supercotrolia/me/contact/${id}/role/${role}`, { method: "DELETE" });

  const createContactAccount = (id: string, language: string) =>
    call(`/supercotrolia/me/contact/${id}/user`, { data: { language } });

  const deleteContactAccount = (id: string) =>
    call(`/users/${id}`, { method: "DELETE" });

  return {
    createContact,
    updateContact,
    deleteContact,
    addContactRole,
    deleteContactRole,
    createContactAccount,
    deleteContactAccount,
  };
};
