import React, { useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/core/input/CotroliaLoadingButton";
import SendIcon from "@material-ui/icons/Send";
import ContentTwoSided, {
  LeftSide,
  RightSide,
} from "../../../components/core/ContentTwoSided";
import { validateEmail, validPhoneNumber } from "../../../utils/validation";
import { DistributorEdit } from "../types/DistributorsTypes";
import { useDistributors } from "../hooks/useDistributors";
import FieldDistributorName from "./fields/FieldDistributorName";
import FieldDistributorPhone from "./fields/FieldDistributorPhone";
import FieldDistributorCode from "./fields/FieldDistributorCode";
import FieldDistributorEmail from "./fields/FieldDistributorEmail";
import FieldDistributorSiretTVA from "./fields/FieldDistributorSiretTVA";
import FieldDistributorAddress from "./fields/FieldDistributorAddress";
import ToggleSection from "../../../components/core/ToggleSection";
import { DriveEta, Star } from "@material-ui/icons";
import FieldDistributorFirstname from "./fields/FieldDistributorFirstname";
import FieldDistributorLastname from "./fields/FieldDistributorLastname";
import i18next from "i18next";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

interface DistributorFormErrors {
  nameError?: string;
  firstameError?: string;
  lastnameError?: string;
  phoneError?: string;
  addressError?: string;
  distributorCodeError?: string;
  emailError?: string;
  vatError?: string;
  contactError?: string;
}

const DistributorForm: React.FC<{ distributor?: DistributorEdit }> = (
  props,
) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const navigate = useNavigate();

  const classes = makeStyles((theme: Theme) => ({
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  }))();

  const { createDistributor } = useDistributors();
  const [distributor, setDistributor] = useState<DistributorEdit | undefined>(
    props.distributor,
  );
  const [errors, setErrors] = useState<DistributorFormErrors>({});
  const [sending, setSending] = useState(false);
  const [isSiretTvaValid, setSiretTvaValid] = useState<boolean>(false);

  const validateForm = () => {
    const formErrors: DistributorFormErrors = {
      nameError: !distributor?.name
        ? t("RequiredFieldDistributorNameError")
        : undefined,
      firstameError: !distributor?.firstname
        ? t("RequiredFieldDistributorFirstnameError")
        : undefined,
      lastnameError: !distributor?.lastname
        ? t("RequiredFieldDistributorLastnameError")
        : undefined,
      phoneError:
        !distributor?.phone || !validPhoneNumber(distributor?.phone)
          ? t("RequiredFieldPhoneError")
          : undefined,
      distributorCodeError: !distributor?.distributerCode
        ? t("RequiredFieldDistributorCodeError")
        : undefined,
      emailError:
        !distributor?.email || !validateEmail(distributor?.email)
          ? t("RequiredFieldEmail")
          : undefined,
      vatError:
        !distributor?.vat || !isSiretTvaValid
          ? t("RequiredFieldDistributorSiretTVA")
          : undefined,
    };
    setErrors(formErrors);
    return (
      !formErrors.nameError &&
      !formErrors.phoneError &&
      !formErrors.addressError &&
      !formErrors.distributorCodeError &&
      !formErrors.emailError &&
      !formErrors.vatError &&
      !formErrors.contactError
    );
  };

  const send = async () => {
    if (validateForm()) {
      setSending(true);

      const newDistributor: DistributorEdit = {
        ...distributor,
        language: i18next.language,
      };
      createDistributor(newDistributor)
        .then(() => {
          const myAlert = alert.show("", {
            title: t("CreateDistributorSuccess"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "success",
          } as unknown as AlertCustomOptions);
          navigate("/distributors");
        })
        .catch(() => {
          const myAlert = alert.show("", {
            title: t("CreateDistributorError"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        })
        .finally(() => setSending(false));
    }
  };

  return (
    <>
      <ToggleSection
        title={t("MainContact")}
        icon={<Star />}
        description={t("DistributorMainContactDesc")}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldDistributorFirstname
              value={distributor?.firstname}
              onChange={(value) =>
                setDistributor({ ...distributor, firstname: value })
              }
              error={errors.firstameError}
            />
            <FieldDistributorEmail
              value={distributor?.email}
              onChange={(value) =>
                setDistributor({ ...distributor, email: value })
              }
              error={errors.emailError}
            />
          </LeftSide>
          <RightSide>
            <FieldDistributorLastname
              value={distributor?.lastname}
              onChange={(value) =>
                setDistributor({ ...distributor, lastname: value })
              }
              error={errors.lastnameError}
            />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>

      <ToggleSection
        title={t("TheDistributor")}
        icon={<DriveEta />}
        description={t("NewGarageDesc")}
        defaultExpanded={true}
      >
        <ContentTwoSided>
          <LeftSide>
            <FieldDistributorName
              value={distributor?.name}
              onChange={(value) =>
                setDistributor({ ...distributor, name: value })
              }
              error={errors.nameError}
            />
            <FieldDistributorPhone
              value={distributor?.phone}
              onChange={(value) =>
                setDistributor({ ...distributor, phone: value })
              }
              error={errors.phoneError}
            />
            <FieldDistributorCode
              value={distributor?.distributerCode}
              onChange={(value) =>
                setDistributor({ ...distributor, distributerCode: value })
              }
              error={errors.distributorCodeError}
            />
          </LeftSide>
          <RightSide>
            <FieldDistributorSiretTVA
              value={distributor?.vat}
              onChange={(value) =>
                setDistributor({ ...distributor, vat: value })
              }
              onValidateTVA={setSiretTvaValid}
              error={errors.vatError}
            />
            <FieldDistributorAddress
              value={distributor?.address}
              onChange={(value) =>
                setDistributor({ ...distributor, address: value })
              }
              error={errors.addressError}
            />
          </RightSide>
        </ContentTwoSided>
      </ToggleSection>
      <div className={classes.btnContainer}>
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={() => send()}
          isLoading={sending}
          loadingLabel={t("WaitingSendDistributor")}
          originalLabel={t("SendDistributor")}
          style={{ padding: "16px 24px", borderRadius: 6 }}
        >
          {t("SendDistributor")}
        </LoadingButton>
      </div>
    </>
  );
};

export default DistributorForm;
