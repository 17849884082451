import { useCallback } from "react";
import { Garage, GarageEdit } from "../types/GarageTypes";
import { useCompanies } from "../../companies/hooks/useCompanies";
import {
  CompanyFilter,
  CompanySort,
} from "../../companies/types/CompaniesTypes";

export const useGarages = (): {
  searchGarages: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Garage[]; total: number }>;
  createGarage: (garage: GarageEdit) => Promise<void>;
} => {
  const { searchCompanies, createCompany } = useCompanies();

  const searchGarages = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Garage[]; total: number }> =>
      searchCompanies(limit, page, search, sort),
    [searchCompanies],
  );

  const createGarage = (garage: GarageEdit): Promise<void> => {
    return createCompany(garage);
  };

  return {
    searchGarages,
    createGarage,
  };
};
