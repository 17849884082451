import { Company, CompanyEdit } from "../../companies/types/CompaniesTypes";

export interface Garage extends Company {
  customerCode?: string;
  shopCode?: string;
  status?: GarageStatus;
}

export interface GarageEdit extends CompanyEdit {
  customerCode?: string;
  shopCode?: string;
  status?: GarageStatus;
}

export enum GarageStatus {
  Active = 1,
  Blocked = 2,
}
