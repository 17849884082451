import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertCustomOptions, useAlert } from "react-alert";

import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Divider,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

import SelectOrAddContact from "../../../components/core/SelectOrAddContact";
import AddContactForm from "../../../components/core/AddContactForm";
import { Popup, PopupCard } from "../../../components/core/Popup";
import { useAuth } from "../../auth/hooks/useAuth";
import { useContacts } from "../../user/hooks/useContacts";
import { Contact, ContactType } from "../../user/types/userTypes";
import _ from "lodash";

const useStyle = makeStyles((theme) => ({
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

const ContactEntry: React.FC<{ contact: Contact }> = (props) => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { user, setUser } = useAuth();
  const {
    updateContact,
    deleteContact,
    deleteContactRole,
    createContactAccount,
    deleteContactAccount,
  } = useContacts();
  const { i18n } = useTranslation();

  const handleDelete = () => {
    deleteContactRole(props.contact.id!, ContactType.Tech)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, type: undefined } : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("TechContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleEdit = (contact: Contact) => {
    updateContact(contact)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? contact : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotModified"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
    setEditMode(false);
  };

  const handleCreateAccount = () => {
    createContactAccount(props.contact.id!, i18n.language)
      .then(() => {
        const myAlert = alert.show(
          `${t("MailSent")} ${props.contact.email} ${t("ToCreatePassword")}`,
          {
            title: t("AccountCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "success",
          } as unknown as AlertCustomOptions,
        );

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, type: ContactType.Tech } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          const myAlert = alert.show(t("RegisterErrorEmailAlreadyExists"), {
            title: t("AccountNotCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        } else {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("AccountNotCreated"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        }
      });
  };

  const handleDeleteAccount = () => {
    deleteContactAccount(props.contact.fk_user!)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("AccountDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, fk_user: undefined } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("AccountNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handlePermanentDelete = () => {
    deleteContact(props.contact.id!)
      .then(() => {
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.filter(
              (c) => c.id !== props.contact.id,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <Box className={classes.entryContainer}>
        <PersonIcon />
        <Typography variant="body1" className={classes.entryLabel}>
          {props.contact.lastname + " " + props.contact.firstname}
        </Typography>
        {props.contact.fk_user === null ? (
          <Tooltip title={t("CreateAccount")}>
            <IconButton
              className={classes.entryBtn}
              onClick={handleCreateAccount}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        ) : user!.email === props.contact.email ? (
          <Tooltip title={t("MainAccountNotDeletable")}>
            <span>
              <IconButton className={classes.entryBtn} disabled>
                <PersonAddDisabledIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={t("DeleteAccount")}>
            <IconButton
              className={classes.entryBtn}
              onClick={handleDeleteAccount}
            >
              <PersonAddDisabledIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("EditContact")}>
          <IconButton
            className={classes.entryBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("UnlinkContact")}>
          <IconButton className={classes.entryBtn} onClick={handleDelete}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {editMode && (
        <Popup>
          <PopupCard
            title={t("EditTechContact")}
            description={
              t("EditTechContactDesc") +
              " : " +
              props.contact.lastname +
              " " +
              props.contact.firstname +
              " <" +
              props.contact.email +
              ">"
            }
          >
            <AddContactForm
              lastname={props.contact.lastname}
              firstname={props.contact.firstname}
              email={props.contact.email}
              phone={props.contact.phone}
              addBtnLabel={t("Modify")}
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) =>
                handleEdit({
                  ...props.contact,
                  lastname,
                  firstname,
                  email,
                  phone,
                })
              }
              handleCancel={() => setEditMode(false)}
            />
          </PopupCard>
          {user!.email !== props.contact.email && (
            <PopupCard
              title={t("DeleteContact")}
              description={
                t("DeleteContactDesc") +
                " : " +
                props.contact.lastname +
                " " +
                props.contact.firstname +
                " <" +
                props.contact.email +
                ">"
              }
              variant="outlined"
              color="secondary"
              action={handlePermanentDelete}
              actionLabel={t("Delete")}
              actionIcon={<DeleteIcon />}
            ></PopupCard>
          )}
        </Popup>
      )}
    </>
  );
};

const ProfileTechnicalContact: React.FC = () => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const { createContact, addContactRole } = useContacts();

  const [addMode, setAddMode] = useState(false);
  const contactList = _.uniqBy(
    user!.thirdparty.contactList,
    (c) => c.id,
  ).filter((contact) => contact.type === ContactType.Tech);

  const handleAdd = (id: string) => {
    addContactRole(id, ContactType.Tech)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("TechContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === id ? { ...c, type: ContactType.Tech } : c,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("TechContactNotAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleCreate = (contact: Contact) => {
    createContact({ ...contact, type: ContactType.Tech })
      .then(() => {
        const myAlert = alert.show("", {
          title: t("TechContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: [
              ...user!.thirdparty.contactList,
              { ...contact, type: ContactType.Tech },
            ],
          },
        });
        setAddMode(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 403) {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("TechContactNotAdded403"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        } else {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("TechContactNotAdded"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        }
      });
  };

  return (
    <>
      <Box className={classes.margin}>
        <SelectOrAddContact
          required={false}
          selected=""
          onSelect={handleAdd}
          onClick={() => setAddMode(true)}
          excludeList={contactList as never[]}
        />
        {addMode && (
          <>
            <Divider className={classes.divider} />
            <AddContactForm
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) => handleCreate({ lastname, firstname, email, phone })}
              handleCancel={() => setAddMode(false)}
            />
          </>
        )}
      </Box>
      <Divider />
      <Box className={classes.margin}>
        <Box className={classes.listEntryContainer}>
          {contactList.map((contact, index) => (
            <ContactEntry key={index} contact={contact} />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ProfileTechnicalContact;
