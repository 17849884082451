import { useCallback } from "react";
import { Distributor } from "../types/DistributorsTypes";
import { useCompanies } from "../../companies/hooks/useCompanies";
import {
  CompanyFilter,
  CompanySort,
} from "../../companies/types/CompaniesTypes";

export const useDistributors = (): {
  searchDistributors: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Distributor[]; total: number }>;
  createDistributor: (distributor: Distributor) => Promise<void>;
} => {
  const { searchCompanies, createCompany } = useCompanies();

  const searchDistributors = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Distributor[]; total: number }> =>
      searchCompanies(limit, page, search, sort),
    [searchCompanies],
  );

  const createDistributor = (distributor: Distributor): Promise<void> => {
    return createCompany(distributor);
  };

  return {
    searchDistributors,
    createDistributor,
  };
};
