import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertCustomOptions, useAlert } from "react-alert";

import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import SelectOrAddContact from "../../../components/core/SelectOrAddContact";
import AddContactForm from "../../../components/core/AddContactForm";
import { useAuth } from "../../auth/hooks/useAuth";
import { Popup, PopupCard } from "../../../components/core/Popup";
import { useContacts } from "../../user/hooks/useContacts";
import { Contact, ContactType } from "../../user/types/userTypes";
import _ from "lodash";

const useStyle = makeStyles((theme) => ({
  listEntryContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1) + 2,
    position: "relative",
  },
  entryContainer: {
    display: "flex",
    alignItems: "center",
    padding: `0 ${theme.spacing(2)}px`,
  },
  entryLabel: {
    flexGrow: 1,
  },
  entryBtn: {
    color: theme.palette.text.secondary,
  },
}));

const ContactEntry: React.FC<{ contact: Contact }> = (props) => {
  const classes = useStyle();
  const alert = useAlert();
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { updateContact, deleteContact, deleteContactRole } = useContacts();

  const handleDelete = () => {
    deleteContactRole(props.contact.id!, ContactType.Billing)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? { ...c, type: undefined } : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("BillingContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleEdit = (contact: Contact) => {
    updateContact(contact)
      .then(() =>
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === props.contact.id ? contact : c,
            ),
          },
        }),
      )
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotModified"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
    setEditMode(false);
  };

  const handlePermanentDelete = () => {
    deleteContact(props.contact.id!)
      .then(() => {
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.filter(
              (c) => c.id !== props.contact.id,
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("ContactNotDeleted"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  return (
    <>
      <Box className={classes.entryContainer}>
        <PersonIcon />
        <Typography variant="body1" className={classes.entryLabel}>
          {props.contact.lastname + " " + props.contact.firstname}
        </Typography>
        <Tooltip title={t("EditContact")}>
          <IconButton
            className={classes.entryBtn}
            onClick={() => setEditMode(!editMode)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("UnlinkContact")}>
          <IconButton className={classes.entryBtn} onClick={handleDelete}>
            <LinkOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {editMode && (
        <Popup>
          <PopupCard
            title={t("EditBillingContact")}
            description={
              t("EditBillingContactDesc") +
              " : " +
              props.contact.lastname +
              " " +
              props.contact.firstname +
              " <" +
              props.contact.email +
              ">"
            }
          >
            <AddContactForm
              lastname={props.contact.lastname}
              firstname={props.contact.firstname}
              email={props.contact.email}
              phone={props.contact.phone}
              addBtnLabel={t("Modify")}
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) =>
                handleEdit({
                  ...props.contact,
                  lastname,
                  firstname,
                  email,
                  phone,
                })
              }
              handleCancel={() => setEditMode(false)}
            />
          </PopupCard>
          {user!.email !== props.contact.email && (
            <PopupCard
              title={t("DeleteContact")}
              description={
                t("DeleteContactDesc") +
                " : " +
                props.contact.lastname +
                " " +
                props.contact.firstname +
                " <" +
                props.contact.email +
                ">"
              }
              variant="outlined"
              color="secondary"
              action={handlePermanentDelete}
              actionLabel={t("Delete")}
              actionIcon={<DeleteIcon />}
            ></PopupCard>
          )}
        </Popup>
      )}
    </>
  );
};

const ProfileBillingContact: React.FC = () => {
  const classes = useStyle();
  const alert = useAlert();
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const { createContact, addContactRole } = useContacts();

  const [addMode, setAddMode] = useState(false);
  const contactList = _.uniqBy(
    user!.thirdparty.contactList,
    (c) => c.id,
  ).filter((contact) => contact.type === ContactType.Billing);

  const handleAdd = (id: string) => {
    addContactRole(id, ContactType.Billing)
      .then(() => {
        const myAlert = alert.show("", {
          title: t("BillingContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        // Update local user informations
        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: user!.thirdparty.contactList.map((c) =>
              c.id === id
                ? { ...c, type: ContactType.Billing }
                : {
                    ...c,
                    type: c.type === ContactType.Billing ? undefined : c.type,
                  },
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        const myAlert = alert.show(t("TryAgainLater"), {
          title: t("BillingContactNotAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "error",
        } as unknown as AlertCustomOptions);
      });
  };

  const handleCreate = (contact: Contact) => {
    createContact({ ...contact, type: ContactType.Billing })
      .then(() => {
        const myAlert = alert.show("", {
          title: t("BillingContactAdded"),
          close: () => {
            alert.remove(myAlert);
          },
          type: "success",
        } as unknown as AlertCustomOptions);

        setUser({
          ...user!,
          thirdparty: {
            ...user!.thirdparty,
            contactList: [
              ...user!.thirdparty.contactList,
              { ...contact, type: ContactType.Billing },
            ],
          },
        });
        setAddMode(false);
      })
      .catch((err) => {
        console.error(err);

        if (err.status === 403) {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("BillingContactNotAdded403"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        } else {
          const myAlert = alert.show(t("TryAgainLater"), {
            title: t("BillingContactNotAdded"),
            close: () => {
              alert.remove(myAlert);
            },
            type: "error",
          } as unknown as AlertCustomOptions);
        }
      });
  };

  return (
    <>
      {contactList.length > 0 ? (
        <Box className={classes.listEntryContainer}>
          {contactList.map((contact, index) => (
            <ContactEntry key={index} contact={contact} />
          ))}
        </Box>
      ) : (
        <>
          <SelectOrAddContact
            required={false}
            selected=""
            onSelect={handleAdd}
            onClick={() => setAddMode(true)}
            excludeList={contactList as never[]}
          />
          {addMode && (
            <AddContactForm
              handleAdd={(
                lastname: string,
                firstname: string,
                email: string,
                phone: string,
              ) => handleCreate({ lastname, firstname, email, phone })}
              handleCancel={() => setAddMode(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProfileBillingContact;
