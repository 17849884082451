import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CircleButton from "../components/core/CircleButton";
import { Box, Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate, useLocation } from "react-router";
import Content from "../components/core/layout/Content";
import InterForm from "../features/newintervention/components/InterForm";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import WarningIcon from "@material-ui/icons/Warning";
import { AlertCustomOptions, useAlert } from "react-alert";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useNewInterventionForm } from "../features/newintervention/context/NewInterventionContext";
import { TransitionProps } from "@material-ui/core/transitions";
import { useDevice } from "../hooks/useDevice";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<unknown, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const NewIntervention: React.FC = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const device = useDevice();
  const classes = useStyle();
  const navigate = useNavigate();
  const [displayDialog, setDisplayDialog] = useState(false);
  const query = useQuery();
  const idParam = query.get("id");
  const { fetchConnectedUser } = useAuth();
  const {
    intervention,
    resetIntervention,
    saveForm,
    loadFromSavedIntervention,
  } = useNewInterventionForm();

  useEffect(() => {
    // We want to create a saved inter
    if (!!idParam && intervention.id !== parseInt(idParam)) {
      // Load inter parameters
      loadFromSavedIntervention(idParam).catch((err) => {
        // If intervention not in draf redirect to dashboard (security check)
        if (err.status === 403) {
          window.location.href = "/";
        }
      });
    }
  }, [idParam, intervention.id, loadFromSavedIntervention]);

  const handleCancelClick = () => {
    setDisplayDialog(true);
  };

  const handleCancel = () => {
    setDisplayDialog(false);
  };

  const handleConfirm = () => {
    setDisplayDialog(false);
    resetIntervention();
    navigate(-1);
  };

  const handleSave = async () => {
    try {
      await saveForm(intervention);

      // Fetch me to get updated contact list and intervention numbers
      fetchConnectedUser();

      // Display feedback and redirect
      const myAlert = alert.show("", {
        title: t("SaveInterSuccess"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "success",
      } as unknown as AlertCustomOptions);
      navigate("/");
    } catch (err) {
      console.error(err);
      const myAlert = alert.show(t("CreateInterErrorDesc"), {
        title: t("CreateInterError"),
        close: () => {
          alert.remove(myAlert);
        },
        type: "error",
      } as unknown as AlertCustomOptions);
    }
  };

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={t("NewIntervention")}
        titleIcon={<PersonOutlineIcon />}
        titleDesc=""
      >
        {device.isMobile ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={handleCancelClick}
            >
              <CloseIcon />
            </CircleButton>
            <CircleButton
              onClick={handleSave}
              className={classes.rightBtn}
              variant="contained"
            >
              <SaveIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={handleCancelClick}
            >
              {t("AbortNewIntervention")}
            </Button>
            <Button
              className={classes.rightBtn}
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={handleSave}
            >
              {t("Save")}
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content centered>
        <InterForm />
      </Content>
      {displayDialog && (
        <Dialog
          open={displayDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Box display="flex" alignItems="center">
              <WarningIcon color="primary" style={{ marginRight: "5px" }} />
              {t("ConfirmCancelNewInterTitle")}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("ConfirmCancelNewInterDesc")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t("NotConfirmCancelNewInter")}
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              endIcon={<CancelIcon />}
            >
              {t("ConfirmCancelNewInter")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NewIntervention;
