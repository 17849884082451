import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import MemoryIcon from "@material-ui/icons/Memory";
import CircleButton from "../components/core/CircleButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link, useNavigate } from "react-router-dom";
import Content from "../components/core/layout/Content";
import NoIntervention from "../features/interventions/components/list/NoIntervention";
import MyInterventionsFilters from "../features/interventions/components/filters/MyInterventionsFilters";
import AppConstant from "../utils/appConstant";
import { useInterventions } from "../features/interventions/hooks/useInterventions";
import Datagrid, { ColumnDef } from "../components/core/Datagrid";
import {
  Intervention,
  InterventionFilter,
  InterventionSort,
  InterventionSortFields,
  InterventionStatus,
} from "../features/interventions/types/InterventionTypes";
import { useInterventionStatus } from "../features/interventions/hooks/useInterventionStatus";
import { useDevice } from "../hooks/useDevice";
import { useConfiguration } from "../hooks/useConfiguration";
import moment from "moment";
import { useAuth } from "../features/auth/hooks/useAuth";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    justifyContent: "center",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
  firstCol: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    "&:after": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      content: "''",
      display: "block",
      height: "50%",
    },
  },
  refLink: {
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

const MyInterventions: React.FC = () => {
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const { user } = useAuth();
  const device = useDevice();
  const navigate = useNavigate();
  const classes = useStyle();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<InterventionFilter>({});
  const [sort, setSort] = useState<InterventionSort>({ rowid: "DESC" });
  const { listIntervention } = useInterventions();
  const { getStatusDisplay } = useInterventionStatus();

  useEffect(() => {
    setLoading(true);
    listIntervention(rowsPerPage, page + 1, filters, sort)
      .then((res) => {
        setInterventions(res.results);
        setTotal(res.total);
      })
      .finally(() => setLoading(false));
  }, [filters, listIntervention, page, rowsPerPage, sort]);

  const onRefClick = (intervention: Intervention) => {
    if (
      parseInt(intervention.status.toString()) === InterventionStatus.Registered
    ) {
      navigate("/intervention/new?id=" + intervention.id);
    } else {
      navigate("/intervention/" + intervention.id);
    }
  };

  const prepareColumns = (): ColumnDef<Intervention>[] => {
    const carColumns: ColumnDef<Intervention>[] = [
      {
        header: "Marque",
        format: (value) =>
          configuration.getCarBrandFromModel(value.carType)?.label ?? "-",
      },
      {
        header: "Modèle",
        format: (value) => value.carType?.label ?? "-",
      },
    ];
    const columns: ColumnDef<Intervention>[] = [
      {
        header: "Référence intervention",
        format: (value) => (
          <Typography
            variant="body2"
            className={classes.refLink}
            onClick={() => onRefClick(value)}
          >
            {value.ref}
          </Typography>
        ),
        sortOnField: InterventionSortFields.ref,
        className: classes.firstCol,
        colWidth: "auto",
      },
      {
        header: "Référence client",
        format: (value) => value.customerRef,
      },
      {
        header: "Type d'appareil",
        format: (value) => value.partType?.label ?? "-",
      },
    ];
    columns.push(...(configuration.sector === "1" ? carColumns : []));
    columns.push(
      {
        header: "Création",
        format: (value) => moment.unix(value.dateCreat).format("L"),
        sortOnField: InterventionSortFields.datec,
      },
      {
        header: "Prise en charge",
        format: (value) =>
          value.takeCharge ? moment.unix(value.takeCharge).format("L") : "-",
      },
      {
        header: "Envoi devis",
        format: (value) =>
          value.propal?.sendPropal ? value.propal?.sendPropal : "-",
      },
      {
        header: "Accord devis",
        format: (value) =>
          value.propal?.signedPropal ? value.propal?.signedPropal : "-",
      },
      {
        header: "État intervention",
        format: (value) => (
          <Typography
            style={{
              color: getStatusDisplay(value.status).textColor,
              backgroundColor: getStatusDisplay(value.status).backgroundColor,
              padding: "4px 12px",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "12px",
              textTransform: "uppercase",
              textAlign: "center",
              borderRadius: 4,
            }}
          >
            {getStatusDisplay(value.status).label}
          </Typography>
        ),
        sortOnField: InterventionSortFields.fk_statut,
      },
    );
    return columns;
  };

  return (
    <>
      <Header
        focus={false}
        title={t("MyIntervention")}
        titleIcon={<MemoryIcon />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterListIcon />
              </CircleButton>
              {user?.permissions.createIntervention && (
                <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                  <CircleButton color="primary" variant="contained">
                    <AddCircleOutline />
                  </CircleButton>
                </Link>
              )}
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterListIcon />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("SortOrFilter")}
              </Button>
              {user?.permissions.createIntervention && (
                <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                  <Button
                    style={{ margin: "0px 16px" }}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleOutline />}
                  >
                    {t("NewIntervention")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {!isLoading && total === 0 ? (
          <Box className={classes.root}>
            <NoIntervention />
          </Box>
        ) : (
          <>
            <Collapse in={displayFilter}>
              <MyInterventionsFilters
                applyFilters={(filters) => setFilters(filters)}
              />
            </Collapse>
            <Datagrid
              rows={interventions}
              rowsPerPage={rowsPerPage}
              page={page}
              total={total}
              enablePageSelector
              onChangePage={(p) => setPage(p)}
              columnsDef={prepareColumns()}
              sort={sort}
              onSort={(sort) => setSort(sort)}
              loading={isLoading}
            />
          </>
        )}
      </Content>
    </>
  );
};

export default MyInterventions;
