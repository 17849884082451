import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import { Box, Button, makeStyles } from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import WarningIcon from "@material-ui/icons/Warning";
import { TransitionProps } from "@material-ui/core/transitions";
import { useDevice } from "../hooks/useDevice";
import { EmojiTransportation } from "@material-ui/icons";
import Content from "../components/core/layout/Content";
import DistributorForm from "../features/distributors/components/DistributorForm";

const useStyle = makeStyles((theme) => ({
  leftBtn: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  rightBtn: {
    margin: "0px 16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<unknown, string> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewDistributor: React.FC = () => {
  const { t } = useTranslation();
  const device = useDevice();
  const classes = useStyle();
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  return (
    <>
      <Header
        focus={false}
        variant="primary"
        title={t("NewDistributor")}
        titleIcon={<EmojiTransportation />}
        titleDesc=""
      >
        {device.isMobile ? (
          <HeaderActions>
            <CircleButton
              className={classes.leftBtn}
              onClick={() => setCancelDialogOpen(true)}
            >
              <CloseIcon />
            </CircleButton>
          </HeaderActions>
        ) : (
          <HeaderActions>
            <Button
              className={classes.leftBtn}
              variant="outlined"
              endIcon={<CancelIcon />}
              onClick={() => setCancelDialogOpen(true)}
            >
              {t("AbortNewDistributor")}
            </Button>
          </HeaderActions>
        )}
      </Header>
      <Content centered>
        <DistributorForm />
      </Content>
      {isCancelDialogOpen && (
        <Dialog
          open={isCancelDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClick={() => setCancelDialogOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            <Box display="flex" alignItems="center">
              <WarningIcon color="primary" style={{ marginRight: "5px" }} />
              {t("ExitNewDistributorTitle")}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("ExitNewDistributorDesc")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelDialogOpen(false)} color="primary">
              {t("ExitNewDistributorCancel")}
            </Button>
            <Button
              onClick={() => {}}
              color="primary"
              variant="contained"
              endIcon={<CancelIcon />}
            >
              {t("ExitNewDistributorConfirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NewDistributor;
