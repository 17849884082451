import React, { useEffect, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline,
  EmojiTransportation,
  FilterList,
} from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Garage, GarageStatus } from "../features/garages/types/GarageTypes";
import { useGarages } from "../features/garages/hooks/useGarages";
import NoGarage from "../features/garages/components/NoGarage";
import GaragesFilters from "../features/garages/components/GaragesFilters";
import Datagrid from "../components/core/Datagrid";
import { useAuth } from "../features/auth/hooks/useAuth";
import moment from "moment";
import {
  CompanyFilter,
  CompanySort,
} from "../features/companies/types/CompaniesTypes";

const Garages: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const theme = useTheme();
  const { t } = useTranslation();
  const device = useDevice();
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<CompanyFilter>({});
  const [sort, setSort] = useState<CompanySort>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [garages, setGarages] = useState<Garage[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchGarages } = useGarages();

  useEffect(() => {
    setLoading(true);
    searchGarages(rowsPerPage, page, filters, sort)
      .then((res) => {
        setGarages(res.results);
        setTotal(res.total);
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchGarages, sort]);
  return (
    <>
      <Header
        focus={false}
        title={t("MenuGarages")}
        titleIcon={<EmojiTransportation />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
              {user?.permissions.createNetworkGarage && (
                <Link to="/garage/new" style={{ textDecoration: "none" }}>
                  <CircleButton color="primary" variant="contained">
                    <AddCircleOutline />
                  </CircleButton>
                </Link>
              )}
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("SortOrFilter")}
              </Button>
              {user?.permissions.createNetworkGarage && (
                <Link to="/garage/new" style={{ textDecoration: "none" }}>
                  <Button
                    style={{ margin: "0px 16px" }}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleOutline />}
                  >
                    {t("NewGarage")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {!isLoading && total === 0 ? (
          <Box className={classes.root}>
            <NoGarage />
          </Box>
        ) : (
          <>
            <Collapse in={displayFilter}>
              <GaragesFilters applyFilters={setFilters} />
            </Collapse>
            {garages && (
              <Datagrid
                rows={garages}
                rowsPerPage={rowsPerPage}
                page={page}
                total={total}
                enablePageSelector
                onChangePage={(p) => setPage(p)}
                columnsDef={[
                  {
                    header: "Raison sociale du garage",
                    format: (value) => value.name,
                  },
                  {
                    header: "Code client",
                    format: (value) => value.customerCode,
                  },
                  {
                    header: "Code magasin",
                    format: (value) => value.shopCode,
                  },
                  {
                    header: "Date création",
                    format: (value) =>
                      value.datec ? moment.unix(value.datec).format("L") : "-",
                  },
                  {
                    header: "Email",
                    format: (value) => value.email,
                  },
                  {
                    header: "Statut",
                    format: (value) => (
                      <Typography
                        variant="body2"
                        style={{ color: theme.palette.success.main }}
                      >
                        {value.status &&
                        parseInt(value.status.toString()) ===
                          GarageStatus.Active
                          ? "Actif"
                          : "Bloqué"}
                      </Typography>
                    ),
                  },
                ]}
                sort={sort}
                onSort={(sort) => setSort(sort)}
                loading={isLoading}
              />
            )}
          </>
        )}
      </Content>
    </>
  );
};

export default Garages;
