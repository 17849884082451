import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import {
  Company,
  CompanyFilter,
  CompanySort,
  CompanyEdit,
} from "../types/CompaniesTypes";

export const useCompanies = (): {
  searchCompanies: (
    limit: number,
    page: number,
    search: CompanyFilter,
    sort: CompanySort,
  ) => Promise<{ results: Company[]; total: number }>;
  createCompany: (company: CompanyEdit) => Promise<void>;
} => {
  const { call } = useApi();

  const searchCompanies = useCallback(
    (
      limit: number,
      page: number,
      search: CompanyFilter,
      sort: CompanySort,
    ): Promise<{ results: Company[]; total: number }> =>
      call("/supercotrolia/companies/search", {
        data: {
          limit,
          page,
          search,
          order: sort,
        },
      }),
    [call],
  );

  const createCompany = (company: CompanyEdit): Promise<void> => {
    return call("/supercotrolia/companies", { data: company });
  };

  return {
    searchCompanies,
    createCompany,
  };
};
