import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Typography,
  FormControl,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";
import CotroliaPassword from "../../../components/core/input/CotroliaPassword";
import clsx from "clsx";
import { ArrowDropDown } from "@material-ui/icons";
import AuthFormContainer from "./AuthFormContainer";

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
  },
  btn: {
    width: "100%",
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(1)}px 0`,
  },
  link: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    marginTop: 10,
  },
  hiddenField: {
    height: 0,
    visibility: "hidden",
  },
}));

const emptyError = {
  value: false,
  msg: "",
};

const LoginForm: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(emptyError);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(emptyError);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState<"email" | "password">("email");
  const refEmail = useRef<HTMLInputElement>(null);
  const refPassword = useRef<HTMLInputElement>(null);

  const validateEmail = () => {
    const isValid = !!email;
    if (!isValid) {
      setEmailError({
        value: true,
        msg: t("FieldRequired"),
      });
    }
    return isValid;
  };

  const validatePassword = () => {
    const isValid = !!password;
    if (!isValid) {
      setPasswordError({
        value: true,
        msg: t("FieldRequired"),
      });
    }
    return isValid;
  };

  const handleEmailChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setEmailError(emptyError);
    setEmail(value);
  };

  const stayOrRedirect = (onStay: () => void) => {
    setLoading(true);
    auth
      .wayf(email)
      .then((stay: boolean) => {
        const redirectUrl = import.meta.env.VITE_LOGIN_AD_REDIRECT_URL;
        if (!stay && !!redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          return onStay();
        }
      })
      .finally(() => setLoading(false));
  };

  const handleLogin = () => {
    if (validateEmail() && validatePassword() && !isLoading) {
      stayOrRedirect(() => auth.login(email, password));
    }
  };

  const handlePrevious = () => {
    setStep("email");
  };

  const handleNext = () => {
    if (validateEmail()) {
      stayOrRedirect(() => {
        setStep("password");
      });
    }
  };

  useEffect(() => {
    if (step === "email") {
      refEmail.current?.focus();
    }
    if (step === "password") {
      refPassword.current?.focus();
    }
  }, [step]);

  return (
    <AuthFormContainer>
      <Typography variant="h4" className={classes.title}>
        {t("Connect")}
      </Typography>

      <>
        <FormControl
          className={clsx(
            classes.inputContainer,
            step === "password" ? classes.hiddenField : "",
          )}
          error={emailError.value}
        >
          <TextField
            id="email"
            label={t("YourEmail")}
            variant="outlined"
            color="secondary"
            aria-describedby="email-helper"
            onChange={handleEmailChange}
            onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                handleNext();
              }
            }}
            value={email}
            error={emailError.value}
            helperText={
              emailError.value ? emailError.msg : t("YourEmailHelperLogin")
            }
            autoFocus
            inputRef={refEmail}
          />
        </FormControl>

        {step === "email" && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            endIcon={
              isLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <ArrowForwardIcon />
              )
            }
            onClick={() => handleNext()}
          >
            {t("loginNext")}
          </Button>
        )}
      </>

      {step === "password" && (
        <Button
          variant="outlined"
          onClick={() => handlePrevious()}
          endIcon={<ArrowDropDown />}
          fullWidth
          style={{
            textTransform: "none",
            fontSize: 16,
            fontWeight: "normal",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
          color="default"
        >
          {email}
        </Button>
      )}

      {/* We keep the password field mounted (but hidden) in the "email" step, to ensure that pre-filling by password managers can work */}
      <CotroliaPassword
        className={clsx(
          classes.inputContainer,
          step === "email" ? classes.hiddenField : "",
        )}
        label={t("YourPassword")}
        value={password}
        helperText={
          passwordError.value ? passwordError.msg : t("YourPasswordHelper")
        }
        error={passwordError.value}
        onChange={(password: string) => {
          setPasswordError(emptyError);
          setPassword(password);
        }}
        ref={refPassword}
        onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === "Enter") {
            handleLogin();
          }
        }}
      />

      {step === "password" && (
        <>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            endIcon={
              isLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <ArrowForwardIcon />
              )
            }
            onClick={() => handleLogin()}
          >
            {!isLoading && t("ConnectMyself")}
            {isLoading && t("Connexion en cours")}
          </Button>

          <Link to={`/forget-password?email=${email}`} className={classes.link}>
            {t("ForgottenPassword")}
          </Link>
        </>
      )}
    </AuthFormContainer>
  );
};

export default LoginForm;
